import React from "react";
import styled from "styled-components";

import SadTicket from "./SadTicket";

const TicketCanceled = styled.div`
  display: flex;
  height: 326px;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: #23bbac;
  color: white;

  font-size: 19px;
  font-weight: 700;

  p {
    text-align: center;
    margin: 0;
    margin-bottom: -80px;
  }
  button {
    height: 44px;
    width: 288px;
    background: #23bbac;
    color: #ffffff;
    border-radius: 24px;
    border: 1px solid #c8c7cc;

    font-size: 17px;
    font-weight: 900;
  }

  .back-home {
    background: #ffffff;
    color: #23bbac;
  }
`;

const ButtonContainer = styled.div`
  height: 107px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Canceled = ({ number }) => {
  return (
    <>
      <SadTicket />

      <TicketCanceled>
        <p>
          Votre ticket n°{number} <br /> a été annulé
        </p>

        <ButtonContainer>
          <button className="back-home">Retour à l'accueil</button>
          <button>Donner mon avis</button>
        </ButtonContainer>
      </TicketCanceled>
    </>
  );
};
export default Canceled;
