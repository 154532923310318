import styled from "styled-components";
import { toggleFullScreen } from "../utils";

const VERSION = process.env.REACT_APP_VERSION || "alpha";

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  p {
    font-weight: 300;
    font-size: 17px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.5);
  }
  em,
  a {
    font-weight: light;
    color: #ffcc09;
  }
`;

const Footer = () => {
  return (
    <Layout>
      <p>
        Conditions générales d’utilisation :{" "}
        <em>
          <a href="https://app.barberconnect.fr/device.apk">
            barberconnect.fr/cgu
          </a>
        </em>
      </p>
      <p onClick={toggleFullScreen}>Version {VERSION}</p>
    </Layout>
  );
};

export default Footer;
