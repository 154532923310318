import React from "react";
import styled from "styled-components";
import "typeface-roboto";
import { ReactComponent as ArrowSVG } from "../../assets/arrow.svg";

const WaitingListLayout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 40px;
  span {
    margin-left: 40px;
    align-self: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    color: #37474f;
  }
  .ticket {
    color: ${(props) => props.theme.main};
  }
  .ticketUser {
    color: white;
    background-color: ${(props) => props.theme.main};
    justify-content: space-between;
    p {
      display: flex;
      flex-direction: row;
      font-weight: bold;
      margin-left: 17px;
    }
  }
`;
const WaitingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 62px;
  width: 324px;
  margin-left: 8px;
  .arrow {
    display: flex;
    align-items: flex-end;
    margin-right: 6px;
    max-height: 38px;
    svg {
    }
  }
`;
const WaitingTicket = styled.div`
  display: flex;
  height: 38px;
  width: 250px;
  background-color: #ffffff;
  border: 1px solid ${(props) => props.theme.main};
  box-sizing: border-box;
  border-radius: 8px;
  justify-content: flex-end;
  align-items: center;

  p {
    margin-right: 20px;
    font-size: 17px;
    font-weight: 700;
  }
`;

const WaitingList = ({ waitingList, ticketId }) => {
  let indexTicketUser = waitingList.findIndex((e) => e.id === ticketId) + 1;

  let isFirstStarted = waitingList[0].startedTime != null;
  let startIndex = Math.max(indexTicketUser - 5, isFirstStarted ? 1 : 0);
  let hiddenTicket = indexTicketUser - 6;

  return (
    <WaitingListLayout>
      {hiddenTicket > 0 && (
        <span>+ {hiddenTicket} autres personnes avant vous</span>
      )}
      {waitingList.slice(startIndex, indexTicketUser).map((ticket) => (
        <WaitingContainer key={ticket.id}>
          {ticketId === ticket.id && (
            <div className="arrow">
              <ArrowSVG />
            </div>
          )}
          <WaitingTicket
            className={ticketId === ticket.id ? "ticketUser" : "ticket"}
          >
            {ticketId === ticket.id ? <p>votre position</p> : null}
            <p>n°{ticket.number}</p>
          </WaitingTicket>
        </WaitingContainer>
      ))}
    </WaitingListLayout>
  );
};

export default WaitingList;
