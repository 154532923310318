import React from "react";
import angry from "../assets/angry.svg";

import {
  TicketLayout,
  TicketFooter,
  TicketHeader,
  TicketBody,
  TicketTitle,
} from "./TicketLayout";

const TicketMissing = ({ ticket, onCancel }) => {
  return (
    <TicketLayout style={{ backgroundColor: "rgba(255, 167, 66, 1)" }}>
      <TicketHeader>
        <span className="text-number">N° de ticket</span>
        <TicketTitle>
          <h2>{ticket.date}</h2>
          <h2>{ticket.details.number}</h2>
        </TicketTitle>
      </TicketHeader>

      <TicketBody>
        <h1>
          Absence
          <img src={angry} alt="angry" />
        </h1>

        <span className="text-bold-orange">
          Vous ne vous êtes pas présenter à temps
        </span>

        <p>
          Merci de vous présenter à l’accueil pour réintégrer la salle d’attente
          ou annuler définitivement votre ticket.
        </p>
      </TicketBody>

      <TicketFooter>
        <button onClick={onCancel}>Annuler</button>
      </TicketFooter>
    </TicketLayout>
  );
};

export default TicketMissing;
