import React from "react";
import styled from "styled-components";
import { ReactComponent as LogoLigthSVG } from "../assets/logo.svg";
import { ReactComponent as FacebookSVG } from "../assets/facebook.svg";
import { ReactComponent as InstagramSVG } from "../assets/instagram.svg";
import Rating from "../Ticket/components/Rating";

const Layout = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;

  background: #312c2c;
  color: #fff;

  .logo {
    padding-right: 2rem;
    height: 100%;
  }

  .follow {
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
  }

  .row {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    a {
      margin-right: 28px;
    }
  }
`;

export function HomePage() {
  return (
    <Layout>
      <Rating />
      <div className="row">
        <LogoLigthSVG className="logo" />
        <div className="row follow">
          <a href="https://www.facebook.com/barberconnectpro">
            <FacebookSVG />
          </a>
          <a href="https://www.instagram.com/barberconnectpro/">
            <InstagramSVG />
          </a>
        </div>
      </div>
    </Layout>
  );
}

export default HomePage;
