import { useState, useEffect } from "react";

/**
 *
 * @returns {Date}
 */
function useNow() {
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    let timer = setInterval(() => setNow(new Date()), 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return now;
}

export default useNow;
