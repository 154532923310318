import { useState } from "react";
import styled from "styled-components";
import { ReactComponent as VoitureSVG } from "../assets/car.svg";
import { ReactComponent as VeloSVG } from "../assets/bike.svg";
import { ReactComponent as ShoeSVG } from "../assets/shoe.svg";
import { ReactComponent as ToggleLogoSVG } from "../assets/toggle-logo.svg";
import useNow from "../../../hooks/useNow";

const DropDownContainer = styled.div`
  margin: 0 auto;
  width: auto;
`;
const DropDownHeader = styled.div`
  display: flex;
  margin-bottom: 0.8em;
  padding-left: 5px;
  padding-right: 5px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 1.3rem;
  font-weight: 700;
  color: black;
  background: #ffffff;
  height: 41px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;
  svg {
    max-width: 40px;
    height: auto;
  }
  &:hover {
    box-shadow: inset 0px 0px 0px 3px ${(props) => props.theme.border};
  }
`;
const DropDownListContainer = styled.div`
  position: absolute;
`;

const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  border-radius: 3px;
  color: black;
  font-weight: 500;
  position: relative;
  z-index: 1;
  &:first-child {
    padding-top: 0.8em;
  }
`;

const ListItem = styled.li`
  list-style: none;
  margin-bottom: 0.8em;
  padding-right: 0.5em;
  padding-left: ${(props) => (props.vehicule ? 0 : "0.5em")};
  width: auto;
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.main};
    color: #fff;
  }

  svg {
    width: 40px;
    height: auto;
  }
`;
const Closer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const PathTimeSelect = ({ time, setTime, end, times }) => {
  const now = useNow();
  const [isOpen, setIsOpen] = useState(false);

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (value) => () => {
    setTime(value);
    setIsOpen(false);
  };

  const options = times.filter((minutes) => {
    let m = now.getTime() + minutes * 60 * 1000;
    return m < end;
  });
  const showTime = (time) => {
    const hours = Math.floor(time / 60)
      .toString()
      .padStart(2, "0");
    const minutes = (time % 60).toString().padStart(2, "0");
    if (hours === "00") {
      return `${minutes}min`;
    }
    return `${hours}h${minutes}`;
  };
  return (
    <DropDownContainer>
      {isOpen && <Closer onClick={toggling} />}
      <DropDownHeader onClick={toggling}>
        {showTime(time)}
        <ToggleLogoSVG />
      </DropDownHeader>
      {isOpen && (
        <DropDownListContainer>
          <DropDownList>
            {options.map((option, idx) => (
              <ListItem onClick={onOptionClicked(option)} key={idx}>
                {showTime(option)}
              </ListItem>
            ))}
          </DropDownList>
        </DropDownListContainer>
      )}
    </DropDownContainer>
  );
};

export const VehiculeSelect = ({ vehicule, setVehicule }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (value) => () => {
    setVehicule(value);
    setIsOpen(false);
  };

  const options = ["voiture", "velo", "pied"];

  return (
    <DropDownContainer>
      {isOpen && <Closer onClick={toggling} />}
      <DropDownHeader onClick={toggling}>
        {vehicule === "voiture" && (
          <>
            <VoitureSVG />
            <ToggleLogoSVG />
          </>
        )}
        {vehicule === "velo" && (
          <>
            <VeloSVG />
            <ToggleLogoSVG />
          </>
        )}
        {vehicule === "pied" && (
          <>
            <ShoeSVG />
            <ToggleLogoSVG />
          </>
        )}
      </DropDownHeader>
      {isOpen && (
        <DropDownListContainer className="vehicule">
          <DropDownList style={{ paddingLeft: "0" }}>
            {options.map((option, idx) => (
              <ListItem onClick={onOptionClicked(option)} key={idx} vehicule>
                {option === "voiture" && <VoitureSVG />}
                {option === "velo" && <VeloSVG />}
                {option === "pied" && <ShoeSVG />}
              </ListItem>
            ))}
          </DropDownList>
        </DropDownListContainer>
      )}
    </DropDownContainer>
  );
};
