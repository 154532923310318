import React from "react";
import styled from "styled-components";

import { ReactComponent as SadTicketSVG } from "../assets/sadTicket.svg";

const SadTicketLayout = styled.div`
  background-color: #e7f6ff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 279px;
`;

const SadTicket = () => {
  return (
    <SadTicketLayout>
      <SadTicketSVG />
    </SadTicketLayout>
  );
};

export default SadTicket;
