import styled from "styled-components";
import { ReactComponent as LogoSVG } from "../assets/logo.svg";

export const HeaderLayout = styled.div`
  height: 50px;
  width: 100%;
  background-color: ${(props) => props.theme.main};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
`;

export const Header = () => {
  return (
    <HeaderLayout>
      <LogoSVG />
    </HeaderLayout>
  );
};

export default Header;
