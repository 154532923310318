import styled from "styled-components";

export const TicketContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 23px;
  padding-right: 23px;

  .ticket-title {
    font-size: 19px;
    font-weight: 700;
    color: #37474f;
    margin-left: 23px;
    align-self: center;
    max-width: 600px;
    width: 100%;
  }
`;

export const TicketLayout = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  color: white;

  background-color: ${(props) =>
    props.userTurn ? "#23BBAC" : props.theme.main};
  max-width: 600px;
  width: 100%;
  margin-bottom: 2rem;
  z-index: 1;
`;

export const TicketHeader = styled.div`
  display: flex;
  height: 84px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-right: 1.3rem;
  padding-left: 1.3rem;
`;

export const TicketTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;

  h2 {
    margin: 0;
  }
`;

export const TicketBody = styled.div`
  background-color: white;
  color: black;
  font-size: 15px;
  padding: 30px;
  padding-bottom: 23px;

  p,
  h1,
  span {
    margin-top: 0;
    margin-bottom: 0;
  }
  h1 {
    display: flex;
    font-size: ${(props) => (props.userTurn ? "46px" : "54px")};
    font-weight: 700;
    color: #37474f;
    margin-top: 2px;
    margin-bottom: ${(props) => (props.userTurn ? "0px" : "20px")};
    justify-content: space-between;
    img {
      display: flex;
      margin-top: -16px;
      max-height: 70px;
    }
  }
  p {
    font-size: 13px;
    font-weight: 300;
  }
  .text-bold-orange {
    font-weight: bold;
    color: #ff5d29;
  }
`;

export const TicketFooter = styled.div`
  height: 77px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  button {
    height: 39px;
    width: 129px;
    border: 1px solid #ffffff;
    color: #ffffff;
    box-sizing: border-box;
    border-radius: 24px;
    background-color: transparent;

    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 17px;
    font-weight: 700;
  }
`;

export const ModalContainer = styled.div`
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 506px;
  width: 325px;
  opacity: 1;

  /* Bleu médicale */
  background-color: ${(props) => props.theme.main};
  box-shadow: 5px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 12px;

  color: #ffffff;

  p {
    font-size: 19px;
    font-weight: 700;
  }

  span {
    font-size: 17px;
    font-weight: 300;
    text-align: center;
  }

  button {
    height: 44px;
    width: 288px;
    border-radius: 24px;
    border: 1px solid #c8c7cc;
    box-sizing: border-box;
    border-radius: 24px;

    font-size: 17px;
    font-weight: 900;
    background-color: ${(props) => props.theme.main};
    color: #ffffff;
    margin-top: 16px;
    cursor: pointer;
  }
  .cancel {
    background-color: #ffffff;
    color: ${(props) => props.theme.main};
  }
`;
