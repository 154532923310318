import React from "react";
import styled from "styled-components";

import { ReactComponent as LocationSVG } from "../assets/location.svg";
import { ReactComponent as PinSVG } from "../assets/pin.svg";
import { ReactComponent as InfoSVG } from "../assets/info.svg";
import { ReactComponent as ContactSVG } from "../assets/contact.svg";

const LocationHeaderLayout = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #312c2c;
  color: #fff;
  margin-top: -50px;
  z-index: 10;

  a svg {
    fill: #fff;
  }
  .status {
    margin: 0;
    padding-bottom: 15px;
    color: #fff;
  }
`;

const LocationHeaderLayoutWhite = styled(LocationHeaderLayout)`
  background-color: #fff;
  color: #261d17;

  a svg {
    fill: #261d17;
  }
  .status {
    margin: 0;
    padding-bottom: 15px;
    color: #fff;
  }
`;

const RowTitle = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 17px;
  padding-top: 17px;
  align-items: center;
  svg,
  img {
    max-height: 50px;
    width: auto;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  justify-content: space-between;
  padding-left: 77px;
  padding-right: 23px;
  margin-bottom: 20px;

  a {
    display: flex;
    flex-direction: row;
    text-decoration: none;

    font-size: 13px;
    font-weight: 300;
    color: inherit;

    svg {
      height: 16px;
      width: 16px;
      margin-right: 4px;
    }
  }
`;

const LocationTitle = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 19px;
  font-weight: 300;
  margin-left: 10px;

  .bold {
    font-weight: 700;
  }
`;

const Status = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  padding-left:1rem;
  padding-right:1rem;
  border-radius: 3px;

  background-color: ${(props) => (props.isOpen ? props.isPaused ? "#0073FF" : "#23BBAC" : "#FF5D29")};

  font-size: 17px;
  font-weight: 700;
`;

const Locationheader = ({ location, isOpen, isPaused }) => {
  const safelocation = location.address.split(" ").join("+");
  const geoUrl = `geo:0,0?q=${safelocation}+${location.zipCode}+${location.city}`;
  const phoneUrl = `tel:+33${location.phone}`;
  const locationType = location.locationType;

  return (
    <LocationHeaderLayout>
      <RowTitle>
        {location.avatar ? (
          <img
            src={location.avatar}
            style={{ borderRadius: "50%" }}
            alt={location.name}
            width="50px"
            height="50px"
          />
        ) : (
          <LocationSVG />
        )}

        <LocationTitle>
          <span>{locationType}</span>
          <span className="bold">{location.name}</span>
        </LocationTitle>
      </RowTitle>

      <Row>
        {location.website && (
          <a href={location.website}>
            <InfoSVG /> Plus d’infos
          </a>
        )}
        <a href={geoUrl}>
          <PinSVG /> Itinéraire
        </a>
        {location.phone && (
          <a href={phoneUrl}>
            <ContactSVG /> Contacter
          </a>
        )}
      </Row>
      <Row className="status">
        <Status isOpen={isOpen} isPaused={isPaused}>{isOpen ? isPaused ? "🖐️ Complet pour le moment..."  : `Ouvert jusqu'à ${location.closeAt.getHours().toString()}h${location.closeAt.getMinutes().toString().padStart('0',2)}` : "Fermé"}</Status>
      </Row>
    </LocationHeaderLayout>
  );
};

export const LocationheaderWhite = ({ location }) => {
  const safelocation = location.address.split(" ").join("+");
  const geoUrl = `geo:0,0?q=${safelocation}+${location.zipCode}+${location.city}`;
  const phoneUrl = `tel:+33${location.phone}`;
  const locationType = location.locationType;

  return (
    <LocationHeaderLayoutWhite>
      <RowTitle>
        {location.avatar ? (
          <img
            src={location.avatar}
            style={{ borderRadius: "50%" }}
            alt={location.name}
            width="50px"
            height="50px"
          />
        ) : (
          <LocationSVG />
        )}

        <LocationTitle>
          <span>{locationType}</span>
          <span className="bold">{location.name}</span>
        </LocationTitle>
      </RowTitle>

      <Row>
        {location.website && (
          <a href={location.website}>
            <InfoSVG /> Plus d’infos
          </a>
        )}
        <a href={geoUrl}>
          <PinSVG /> Itinéraire
        </a>
        {location.phone && (
          <a href={phoneUrl}>
            <ContactSVG /> Contacter
          </a>
        )}
      </Row>
    </LocationHeaderLayoutWhite>
  );
};

export const LocationheaderCard = ({ location }) => {
  const safelocation = location.address.split(" ").join("+");
  const geoUrl = `geo:0,0?q=${safelocation}+${location.zipCode}+${location.city}`;
  const phoneUrl = `tel:+33${location.phone}`;
  const locationType = location.locationType;

  return (
    <LocationHeaderLayoutWhite style={{ marginTop: "0px" }}>
      <RowTitle>
        {location.avatar ? (
          <img
            src={location.avatar}
            style={{ borderRadius: "50%" }}
            alt={location.name}
            width="50px"
            height="50px"
          />
        ) : (
          <LocationSVG />
        )}

        <LocationTitle>
          <span>{locationType}</span>
          <span className="bold">{location.name}</span>
        </LocationTitle>
      </RowTitle>

      <Row>
        {location.website && (
          <a href={location.website}>
            <InfoSVG /> Plus d’infos
          </a>
        )}
        <a href={geoUrl}>
          <PinSVG /> Itinéraire
        </a>
        {location.phone && (
          <a href={phoneUrl}>
            <ContactSVG /> Contacter
          </a>
        )}
      </Row>
    </LocationHeaderLayoutWhite>
  );
};

export default Locationheader;
