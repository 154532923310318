import React from "react";
import styled from "styled-components";
import "typeface-roboto";

const PositionUserLayout = styled.div`
  display: flex;
  flex-direction: row;
  color: #37474f;
  justify-content: space-between;
  margin-top: 58px;
  margin-bottom: 58px;
  margin-left: 22px;
  margin-right: 38px;
  z-index: 1;

  p {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0;
    margin-left: 15px;
    font-size: 13px;
    font-weight: 300;
  }
  .position-title {
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 5px;
  }
`;

const SmallTicket = styled.div`
  display: flex;
  width: 47px;
  height: 58px;
  background-color: ${(props) =>
    props.userTurn ? "#23BBAC" : props.theme.main};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  align-items: center;
`;
const SmallTicketContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 37px;
  background-color: white;
  font-size: 13px;
  font-weight: 300;
  align-items: center;
  justify-content: center;
  line-height: 1;

  .position-number {
    margin-top: 2px;
    font-size: 21px;
    font-weight: 900;
  }
`;

const PositionUser = ({ userPosition, queueInfo }) => {
  return (
    <PositionUserLayout>
      <SmallTicket>
        <SmallTicketContent>
          <span className="position-number">{userPosition}</span>
          {userPosition > 1 ? (
            <span>ème</span>
          ) : userPosition === 1 ? (
            <span>er</span>
          ) : (
            ""
          )}
        </SmallTicketContent>
      </SmallTicket>
      <p>
        <span className="position-title">
          La file d’attente de {queueInfo.name} :{" "}
        </span>
        <span>
          Merci de surveillez l’évolution de votre position dans la file
          d’attente
        </span>
      </p>
    </PositionUserLayout>
  );
};

export default PositionUser;
