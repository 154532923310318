import React from "react";
import styled from "styled-components";

const TicketContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 23px;
  margin-right: 23px;
`;

const TicketLayout = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  color: white;

  margin-top: 40px;
  background-color: #cccccc;
  max-width: 600px;
  width: 100%;
`;

const TicketHeader = styled.div`
  display: flex;
  height: 84px;
  flex-direction: column;
  justify-content: flex-end;
  padding-right: 2rem;
  padding-left: 2rem;
  font-weight: bold;

  .text-number {
    display: flex;
    align-self: flex-end;
    font-weight: 300;
    font-size: 13px;
  }
`;
const TicketTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;

  h2 {
    margin: 0;
  }
`;

const TicketBody = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  color: black;
  font-size: 13px;
  font-weight: 300;
  padding: 30px;
  padding-bottom: 40px;

  p,
  h1 {
    margin-top: 0;
    margin-bottom: 0;
  }
  h1 {
    font-size: 46px;
    font-weight: 700;
    color: #37474f;
    margin-top: 2px;
    margin-bottom: 20px;
  }
`;

const TicketFooter = styled.div`
  height: 77px;
  display: flex;
`;

const TicketDone = ({ ticket }) => {
  return (
    <TicketContainer>
      <TicketLayout>
        <TicketHeader>
          <span className="text-number">N° de ticket</span>
          <TicketTitle>
            <h2>{ticket.date}</h2>
            <h2>{ticket.details.number}</h2>
          </TicketTitle>
        </TicketHeader>

        <TicketBody>
          <p>Notre équipe vous dit :</p>
          <h1>Merci !</h1>
        </TicketBody>

        <TicketFooter></TicketFooter>
      </TicketLayout>
    </TicketContainer>
  );
};

export default TicketDone;
