import React from "react";
import { ReactComponent as AvatarSVG } from "../../assets/barber.svg";

import {
  TicketLayout,
  TicketFooter,
  TicketHeader,
  TicketBody,
  TicketTitle,
  Row,
} from "./TicketLayout";

const TicketStarted = ({ ticket, onCancel }) => {
  let { date, queueInfo } = ticket || "";

  return (
    <TicketLayout userTurn={true}>
      <TicketHeader>
        <span className="text-number">N° de ticket</span>
        <TicketTitle>
          <h2>{date}</h2>
          <h2>{ticket.details.number}</h2>
        </TicketTitle>
      </TicketHeader>

      <TicketBody userTurn={true}>
        <p>Merci d'avoir patienté...</p>
        <h1>C'est à vous</h1>
        <Row>
          <span>👉 {queueInfo && queueInfo.name}</span>
          <AvatarSVG />
        </Row>
        <p>Dernière mise à jour : il y a 1 min</p>
      </TicketBody>

      <TicketFooter userTurn={true}>
        <button onClick={onCancel}>Annuler</button>
      </TicketFooter>
    </TicketLayout>
  );
};

export default TicketStarted;
