import styled from "styled-components";

export const ModalContainer = styled.div`
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 506px;
  width: 325px;
  opacity: 1;

  /* Bleu médicale */
  background-color: ${(props) => props.theme.main};
  box-shadow: 5px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 12px;

  color: #ffffff;

  p {
    font-size: 19px;
    font-weight: 700;
  }

  span {
    font-size: 17px;
    font-weight: 300;
    text-align: center;
  }

  button {
    height: 44px;
    width: 288px;
    border-radius: 24px;
    border: 1px solid #c8c7cc;
    box-sizing: border-box;
    border-radius: 24px;

    font-size: 17px;
    font-weight: 900;
    background-color: ${(props) => props.theme.main};
    color: #ffffff;
    margin-top: 16px;
    cursor: pointer;
  }
  .cancel {
    background-color: #ffffff;
    color: ${(props) => props.theme.main};
  }
`;
