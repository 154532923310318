import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";

import { ReactComponent as CloserSVG } from "./assets/closer.svg";
import { ReactComponent as NextSVG } from "./assets/next.svg";
import { ReactComponent as PrevSVG } from "./assets/prev.svg";
import { ReactComponent as DotSVG } from "./assets/dot.svg";
import { ReactComponent as EmptyDotSVG } from "./assets/emptyDot.svg";

const GuidageLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #e8f6ff;
`;

const GuideImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  img {
    position: fixed;
    visibility: ${(props) => (props.hidden ? "hidden" : "visible")};
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .closer {
    align-self: flex-end;
    margin-right: 16px;
    margin-top: 16px;
    cursor: pointer;
  }
`;

const Nav = styled.div`
  display: flex;
  flex-direction: column;
  height: 121px;
  width: 293px;
  border-radius: 8px;
  background-color: white;
  align-self: center;
  justify-content: space-between;
  margin-top: auto;
  button {
    font-size: 17px;
    font-style: normal;
    font-weight: 900;
  }
  p {
    font-size: 15px;
    font-weight: 700;
    color: #37474f;
    align-self: center;
    margin-bottom: 0;
  }
  .home-button {
    align-self: center;
    height: 44px;
    width: 262px;
    border-radius: 24px;
    background-color: #fff;
    border: 1px solid #049be5;
    color: #049be5;
    cursor: pointer;
  }
  .ticket-button {
    align-self: center;
    height: 44px;
    width: 262px;
    border-radius: 24px;
    background-color: #ffcc09;
    border: none;
    color: #565656;
    cursor: pointer;
  }
  .nav {
    justify-content: center;
    svg {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
`;

const SwitchButton = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #049be5;
  width: 50px;
  height: 50px;
  border-radius: 15px;
`;

const Guidage = () => {
  const [count, setCount] = useState(0);

  let navigate = useNavigate();
  const Picture = null;

  function goBack() {
    navigate(-1);
  }

  function next() {
    if (count === Picture.length - 1) {
      setCount(0);
    } else {
      setCount((count) => count + 1);
    }
  }
  function prev() {
    if (count === 0) {
      setCount(Picture.length - 1);
    } else {
      setCount((count) => count - 1);
    }
  }
  const handlers = useSwipeable({
    onSwipedLeft: () => next(),
    onSwipedRight: () => prev(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <GuidageLayout {...handlers}>
      <GuideImage>
        {Picture.map((value, index) => {
          if (count === index) {
            return <img src={Picture[index].url} alt="guide" />;
          } else {
            return <img src={Picture[index].url} alt="guide" hidden />;
          }
        })}

        <div className="closer" onClick={goBack}>
          <CloserSVG />
        </div>

        <Nav>
          <p>{`${count + 1} - ${
            Picture[count].info || " pas d'indications "
          }`}</p>
          {(Picture[count].showReturnHome && count === Picture.length - 1 && (
            <button className="home-button" onClick={goBack}>
              Fermer
            </button>
          )) ||
            (Picture[count].showReturnHome && (
              <button className="ticket-button" onClick={goBack}>
                Voir mon ticket
              </button>
            ))}
          {}
          <Row className="nav">
            {Picture.map((value, index) => {
              if (index === count) {
                return <DotSVG key={index} />;
              } else {
                return <EmptyDotSVG key={index} />;
              }
            })}
          </Row>
        </Nav>
        <Row>
          <SwitchButton onClick={prev}>
            <PrevSVG />
          </SwitchButton>
          <SwitchButton onClick={next}>
            <NextSVG />
          </SwitchButton>
        </Row>
      </GuideImage>
    </GuidageLayout>
  );
};

export default Guidage;
