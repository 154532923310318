import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as RatingSVG } from "../assets/rating.svg";
import { ReactComponent as StarSVG } from "../assets/star.svg";
import { ReactComponent as EmptyStarSVG } from "../assets/emptyStar.svg";

const RatingLayout = styled.div`
  display: flex;
  flex-direction: column;

  p {
    align-self: flex-start;
    font-size: 19px;
    font-weight: 700;
  }
`;

const RatingDiv = styled.div`
  background-color: #ffffff;
  color: #000;
  border-radius: 11px;
`;

const Row = styled.div`
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  p {
    margin-left: 10px;
    font-size: 15px;
    font-weight: 300;
  }
`;

const StarContainer = styled.div`
  margin-left: 2px;
  margin-right: 2px;
  cursor: pointer;

  svg:hover {
    path {
      stroke: #049be5;
    }
  }

  svg:active {
    path {
      stroke: #049be5;
      fill: #049be5;
    }
  }
`;

const Rating = ({ rating, placeId }) => {
  const [note, setNote] = useState(1);
  const starList = [1, 2, 3, 4, 5];

  function fill(index) {
    setNote(index);
    if (index > 3) {
      const placeid = placeId || "ChIJwQ4iCehx5kcRpmDUrceh7ao";
      window.location.href = `https://search.google.com/local/writereview?placeid=${placeid}`;
    } else {
      const link = "https://barberconnect.fr/suggestion";
      //"https://docs.google.com/forms/d/e/1FAIpQLSdSbzQhaAMkHO-_j4IJ1ai_94lMouL3cwdqdSDiH9_4n3JQJQ/viewform?usp=pp_url&entry.1254333283=4&entry.908185948=3&entry.79367457=Une+application+client";
      window.location.href = link;
    }
  }

  return (
    <RatingLayout>
      <p>Votre avis compte</p>

      <RatingDiv>
        <Row>
          <RatingSVG />
          <p>
            Vous appréciez le service ? <br /> Vous avez une remarque, une idée
            <br />
            d’amélioration ? Dites-le nous !
          </p>
        </Row>

        <Row>
          {starList.map((index) => (
            <StarContainer key={index} onClick={() => fill(index)}>
              {index <= note ? <StarSVG /> : <EmptyStarSVG />}
            </StarContainer>
          ))}
        </Row>
      </RatingDiv>
    </RatingLayout>
  );
};

export default Rating;
