import React from "react";
import styled from "styled-components";
import "typeface-roboto";
import PositionUser from "./PositionUser";
import { Counter, CounterEmpty } from "./Counter";
import WaitingList from "./WaitingList";

const PositionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PositionLayout = styled.div`
  display: flex;
  flex-direction: column;

  padding-bottom: 14px;
  max-width: 600px;
  width: 100%;
`;

const Position = ({ ticket }) => {
  let { waitingList, id, userPosition, queueInfo } = ticket || "";

  return (
    <PositionContainer>
      <PositionLayout>
        <PositionUser userPosition={userPosition + 1} queueInfo={queueInfo} />
        {waitingList[0].startedTime ? (
          <Counter ticketGoing={waitingList[0]} queueInfo={queueInfo} />
        ) : (
          <CounterEmpty queueInfo={queueInfo} />
        )}

        <WaitingList
          waitingList={waitingList}
          ticketId={id}
          userPosition={userPosition}
        />
      </PositionLayout>
    </PositionContainer>
  );
};

export default Position;
