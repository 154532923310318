import React from "react";
import styled from "styled-components";
import { ReactComponent as LogoSVG } from "../../assets/logo.svg";
import { useNavigate, useParams } from "react-router-dom";

const GuideContainer = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.main};
  margin-top: 50px;
  justify-content: center;
`;
const GuideLayout = styled.div`
  display: flex;
  flex-direction: column;

  color: #ffffff;
  font-size: 15px;
  max-width: 600px;
  width: 100%;

  h3,
  p {
    margin-left: 18px;
  }
  h3 {
    font-size: 19px;
  }
  p {
    font-weight: 300;
    margin-right: 18px;
    span {
      font-weight: bold;
    }
  }
  button {
    height: 44px;
    width: 288px;
    border: 1px solid #ffffff;
    color: #ffffff;
    box-sizing: border-box;
    border-radius: 24px;
    background-color: ${(props) => props.theme.main};
    font-weight: bold;
    font-size: 17px;
    margin-top: 25px;
    margin-bottom: 38px;
    cursor: pointer;
    align-self: center;
  }
`;
const Picture = styled.div`
  display: flex;
  width: 339px;
  height: 259.93px;
  align-self: center;
  align-items: flex-end;
  background-image: url(${(props) => props.url});
  background-size: cover;
`;
const Logo = styled.div`
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  width: 65px;
  height: 65px;
`;

const Guide = ({ steps, avatar }) => {
  let { slug } = useParams();
  let navigate = useNavigate();

  function goGuidage() {
    navigate(`/ticket-view/${slug}/guidage`, { steps });
  }

  const guidePicture = steps[0].url || "";

  return (
    <GuideContainer>
      <GuideLayout>
        <h3>Votre prise en charge</h3>
        <p>
          Une fois arrivé l’heure de votre consultation, voici les{" "}
          <span>consignes de prise en charge </span>
        </p>
        <Picture url={guidePicture}>
          <Logo>
            {avatar ? (
              <img
                src={avatar}
                alt="logo"
                style={{ width: "50px", height: "50px" }}
              />
            ) : (
              <LogoSVG />
            )}{" "}
          </Logo>
        </Picture>
        <button className="button" onClick={goGuidage}>
          Démarrer le guidage
        </button>
      </GuideLayout>
    </GuideContainer>
  );
};

export default Guide;
