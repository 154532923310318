const BarberBlack = "#312C2C";

const barberTheme = {
  main: BarberBlack,
  border: "#049BE5",
  tvHeader: "#000000",
  tvBackground: BarberBlack,
  tvMenu: "#3f3f3f",
  queueBackground: "#261D17",
  textColor: "white",
  ticketBackground: "rgba(255, 255, 255, 0.1)",
  ticketColor: "white",
  currentTicket: "#23BBAC",
  odd: "rgba(255, 255, 255, 0)",
  even: "rgba(255, 255, 255, 0.1)",
};

export default barberTheme;
