import React from "react";
import styled from "styled-components";

const MediaContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const MediaLayout = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
  h3 {
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    margin-left: 18px;
    color: #37474f;
  }

  .iframe-container {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    margin-bottom: 50px;
  }

  .iframe-container iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

const YoutubeEmbed = ({ embedId }) => (
  <div className="video-responsive">
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

const Media = ({ youtube }) => {
  return (
    <MediaContainer>
      <MediaLayout>
        <h3>Pour patienter</h3>
        <div className="iframe-container">
          <YoutubeEmbed embedId={youtube}></YoutubeEmbed>
        </div>
      </MediaLayout>
    </MediaContainer>
  );
};

export default Media;
