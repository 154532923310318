import React from "react";
import styled from "styled-components";

import { ReactComponent as LogoSVG } from "../assets/logo.svg";
import { ReactComponent as FacebookSVG } from "../assets/facebook.svg";
import { ReactComponent as InstagramSVG } from "../assets/instagram.svg";

const FooterLayout = styled.footer`
  background-color: ${(props) => props.theme.main};
  display: flex;
  flex-direction: column;
  color: white;

  font-size: 15px;
  font-weight: 700;
  padding: 25px;
  margin-top: 3rem;
  width: 100%;

  .button {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;

    height: 44px;
    width: 288px;
    border: 1px solid #c8c7cc;
    background-color: ${(props) => props.theme.main};
    color: white;
    box-sizing: border-box;
    border-radius: 24px;

    font-size: 17px;
    font-weight: 900;
    align-self: center;
    cursor: pointer;
    align-items: center;
    padding: 1rem;
    justify-content: center;
    text-align: center;
    margin: 1rem;
  }

  .follow {
    margin-top: 20px;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    border-top: 1px solid;
    padding-top: 1rem;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    a {
      margin-right: 28px;
    }
  }

  .center {
    align-items: center;
    justify-content: center;
  }
  .mwidth {
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Footer = () => {
  return (
    <FooterLayout>
      <div className="row center mwidth">
        <LogoSVG />
        <a className="button" href="https://barberconnect.fr">
          Demandez votre demo
        </a>
      </div>

      <p className="follow mwidth">SUIVEZ-NOUS</p>
      <div className="row mwidth">
        <a href="https://www.facebook.com/barberconnectpro">
          <FacebookSVG />
        </a>
        <a href="https://www.instagram.com/barberconnectpro/">
          <InstagramSVG />
        </a>
      </div>
    </FooterLayout>
  );
};

export default Footer;
