import React from "react";
import styled from "styled-components";
import timer from "../assets/timer.gif";
import AvatarDefault from "../../assets/barber.svg";

import {
  TicketLayout,
  TicketHeader,
  TicketFooter,
  TicketBody,
} from "./TicketLayout";

const WaitingLayout = styled(TicketLayout)`
  .grow {
    flex: 1;
  }
  .ticket-header-tile {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .sep {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    min-height: 80%;
  }

  .text-header {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 13px;
    margin-bottom: 0.3rem;
  }
  .text-title {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 23px;
  }
  .time-info {
    color: rgba(255, 255, 255, 0.8);
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-align: right;
  }
  .hortizontal-sep {
    border: 1px dashed rgba(196, 196, 196, 0.5);
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    height: 1px;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .col {
    display: flex;
    flex-direction: column;
  }
  .avatar-img {
    max-width: 50px;
    border-radius: 50%;
  }
  .avatar {
    margin-left: auto;
    margin-right: auto;
    padding: 0.3rem;
  }
  .text-header {
    margin-right: 1rem;
  }
`;

const TicketWaiting = ({ ticket, queueInfo, onCancel }) => {
  return (
    <WaitingLayout>
      <TicketHeader>
        <div className="ticket-header-tile grow">
          <div className="text-header">Votre passage</div>
          <div className="text-title">A partir de : </div>
        </div>
        <div className="ticket-header-tile">
          <div className="text-header">Minimun</div>
          <div className="time-info">{ticket.minTime}</div>
        </div>
        <div className="sep"></div>
        <div className="ticket-header-tile">
          <div className="text-header">Maximum</div>
          <div className="time-info">{ticket.maxTime}</div>
        </div>
      </TicketHeader>

      <TicketBody>
        <p>Notre estimation</p>
        <h1>
          {ticket.waitingTime}
          <img src={timer} alt="timer" />
        </h1>

        <span className="text-bold-orange">Horaire susceptible de varier</span>

        <p>Dernière mise à jour : il y a 1 min</p>

        <div className="hortizontal-sep" />
        <div className="row">
          <div className="col">
            <div className="text-header">Votre séléction</div>
            <div>Coupe + barbe</div>
          </div>
          <div className="row">
            <div className="col">
              <div className="text-header">Avec</div>
              <div>{ticket.queueInfo.name}</div>
            </div>

            <div className="avatar">
              <img
                alt="barber"
                className="avatar-img"
                src={ticket.queueInfo.avatar || AvatarDefault}
              />
            </div>
          </div>
        </div>
      </TicketBody>

      <TicketFooter>
        <div>
          <div>{ticket.date}</div>
          <div className="number">
            <span className="text-header">N° de ticket </span>
            {ticket.details.number.toString().padStart(2, "0")}
          </div>
        </div>

        <button onClick={onCancel}>Annuler</button>
      </TicketFooter>
    </WaitingLayout>
  );
};

export default TicketWaiting;
