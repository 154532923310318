import React from "react";
import styled from "styled-components";
import { ReactComponent as RatingSVG } from "../assets/rating.svg";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 27px;
  padding-right: 27px;
  padding-bottom: 50px;
  padding-top: 30px;
`;

const FormLayout = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;

  p {
    align-self: flex-start;
    font-size: 19px;
    font-weight: 700;
    color: #37474f;
  }
`;

const FormDiv = styled.div`
  background-color: #ffffff;
  border-radius: 11px;
  display: flex;
  flex-direction: column;

  a {
    align-self: center;
  }
  button {
    height: 39px;
    width: 166px;
    border-radius: 24px;
    border: 1px solid ${(props) => props.theme.main};
    color: ${(props) => props.theme.main};
    background-color: #ffffff;

    font-size: 17px;
    font-weight: 900;
    margin-bottom: 20px;
  }
`;

const Row = styled.div`
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  p {
    margin-left: 10px;
    font-size: 15px;
    font-weight: 300;
  }
`;

const FormRating = ({ Form }) => {
  return (
    <FormContainer>
      <FormLayout>
        <p>Votre avis compte</p>

        <FormDiv>
          <Row>
            <RatingSVG />
            <p>
              Vous appréciez le service ? <br /> Vous avez une remarque, une
              idée
              <br />
              d’amélioration ? Dites-le nous !
            </p>
          </Row>
          <a href={Form.url || "#"}>
            <button>Donner mon avis</button>
          </a>
        </FormDiv>
      </FormLayout>
    </FormContainer>
  );
};

export default FormRating;
