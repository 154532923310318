import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import ErrorBoundary from "./Error";
import { ThemeProvider } from "styled-components";
import theme from "./theme";

import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "core-js/features/array/find";
import "core-js/features/array/includes";
import "core-js/features/number/is-nan";

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
