import React from "react";
import styled from "styled-components";
import { ReactComponent as LogoLigthSVG } from "../assets/logo.svg";
import Footer from "./components/Footer";
import { toggleFullScreen } from "./utils";
import { Button, ButtonOutline } from "./Born/components/Buttons";
import { ReactComponent as InfoSVG } from "../assets/info.svg";
import { useNavigate } from "react-router";

const Layout = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;

  background: #312c2c;
  color: #fff;

  .logo {
    padding-top: 2rem;
  }

  h1 {
    font-size: min(6vh, 60px);
    font-weight: 300;
    line-height: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    text-align: center;
  }
  em {
    font-style: normal;
    font-weight: bolder;
  }
  .content {
    flex: 1;
    width: 90%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .info {
    font-weight: 300;
    font-size: 29px;
    line-height: 34px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
  }
`;

const Error = styled.div`
  width: 100vw;
  height: 3rem;

  background: #ff5d29;
  font-style: italic;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-left: 2rem;
  svg {
    margin-right: 0.5rem;
  }
`;

export function DeviceError({ onReconnect, error }) {
  const navigate = useNavigate();
  return (
    <Layout>
      <Error>
        <InfoSVG />
        <span>Problème d’accès au service</span>
      </Error>
      <LogoLigthSVG className="logo" style={{ height: "auto" }} />
      <h1>Nous rencontrons une difficulté pour accéder au service</h1>
      {error && <p>{error}</p>}
      <div className="content">
        <Button
          style={{ width: "100%" }}
          onClick={() => navigate("/device/born")}
        >
          Reconnexion
        </Button>
        <ButtonOutline
          style={{ width: "100%" }}
          onClick={() => toggleFullScreen()}
        >
          Quitter le mode plein écran
        </ButtonOutline>
      </div>

      <p className="info">
        En cas de diffuculté, appelez nous au 06 61 66 45 22
      </p>
      <Footer />
    </Layout>
  );
}

export default DeviceError;
