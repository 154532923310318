import styled from "styled-components";

export const Outter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #f9f9f9;
  justify-content: space-between;

  .content {
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
    width: 100vw;

    display: flex;
    flex-direction: column;

    h2 {
      font-size: large;
      padding-left: 1rem;
      padding-right: 1rem;
      margin-left: auto;
      margin-right: auto;
      text-align: justify;
    }
  }
`;
