import React, { useState } from "react";
import { Routes, Route, useNavigate, useParams } from "react-router-dom";

import useLocation from "../../hooks/useLocation";
import nextAvailableTime from "../../hooks/nextAvailableTime";
import useScrollTop from "../../hooks/useScrollTop";

import Header from "../components/Header";
import Footer from "../components/Footer";

import { Outter } from "./components/Outter";
import Queueline from "./Queueline";
import Prestations from "./Prestations";
import TicketForm from "./TicketForm";

export const Location = () => {
  const { slug } = useParams();

  const [location, queueLines, notFound] = useLocation(slug);

  const [queueId, setQueueId] = useState(null);
  const [prestation, setPrestation] = useState(null);
  const [pathTimeM, setPathTimeM] = useState(5);
  const [vehicule, setVehicule] = useState("voiture");

  let navigate = useNavigate();

  useScrollTop();

  let queuesTime = [];
  if (location != null && queueLines != null) {
    let startTime = new Date().getTime() + pathTimeM * 60000;
    let ticketDuration = prestation ? prestation.durationS : 20 * 60;
    queuesTime = JSON.parse(JSON.stringify(queueLines.filter((q) => q.status !== "DISABLED")));

    for (let q of queuesTime) {
      q.time = nextAvailableTime(q, startTime, ticketDuration);

      let end = null;
      if(q.worker) {
        end = new Date();
        end.setHours(q.worker.endHour, q.worker.endMinute);
      }
      if (q.time >= (end ?  end : location.closeAt) && q.status === 'AVAILABLE') {
        q.status = "PAUSED";
      }
    }
  }

  if (notFound === true) {
    return (
      <Outter>
        <Header />
        <div className="content">
          <h1>Location not found</h1>
        </div>
        <Footer />
      </Outter>
    );
  }

  if (location == null || queueLines == null) {
    return (
      <Outter>
        <Header />
        <Footer />
      </Outter>
    );
  }

  return (
    <Routes>
      <Route
        index
        element={
          <Prestations
            location={location}
            queueLines={queuesTime}
            onPrestation={(p) => {
              setPrestation(p);
              navigate("queue");
            }}
          />
        }
      />
      <Route
        path="prestations"
        element={
          <Prestations
            location={location}
            queueLines={queuesTime}
            onPrestation={(p) => {
              setPrestation(p);
              navigate("queue");
            }}
          />
        }
      />
      <Route
        path="queue"
        element={
          <Queueline
            queueLines={queuesTime}
            location={location}
            pathTimeM={(location && pathTimeM) || 0}
            vehicule={vehicule}
            setVehicule={setVehicule}
            onPathTimeM={setPathTimeM}
            onQueue={(q) => {
              setQueueId(q.id);
              navigate("ticket-form");
            }}
          />
        }
      />
      <Route
        path="ticket-form"
        element={
          <TicketForm
            location={location}
            queueLines={queuesTime}
            prestationId={prestation && prestation.id}
            queueId={queueId}
            pathTimeM={pathTimeM}
          />
        }
      />
    </Routes>
  );
};

export default Location;
