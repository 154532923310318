import React from "react";
import styled from "styled-components";

const ItemLayout = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 0.3rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-right: 20px;
  padding-left: 20px;

  cursor: pointer;
  user-select: none;
  width: 100%;
  background-color: ${(props) =>
    props.checked ? "none" : "rgba(255, 255, 255, 0.3)"};
  border-radius: 10px;
  color: #37474f;

  font-size: normal;

  @media (max-width: 543px) {
    font-size: 15px;
  }

  .price {
    flex: 1;
    text-align: right;
    font-weight: bold;
  }

  .name {
    margin-left: 1rem;
    margin-right: 1rem;
    font-weight: 450;
    flex: 4;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

  .checkmark {
    height: 20px;
    width: 20px;
    border: 1px solid #37474f;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  input ~ .checkmark {
  }
  :hover input ~ .checkmark {
  }
  input:checked ~ .checkmark {
  }

  .checkmark:after {
    content: "";
    padding: 0;
    margin: 0;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: inline-block;
  }
  .checkmark:after {
    margin: auto;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    background: #37474f;
  }
`;

export const Item = ({ prestation, checked, onClick }) => {
  return (
    <ItemLayout onClick={onClick} checked={checked}>
      {prestation.price ? (
        <div className="price">
          {prestation.price / 100},
          {(prestation.price % 100).toString().padStart(2, "0")}€
        </div>
      ) : null}
      <div className="name">{prestation.name}</div>
      <input type="radio" name="prestation" />
      <div className="checkmark"></div>
    </ItemLayout>
  );
};

export default Item;
