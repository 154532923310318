export function ticketsWithTime(tickets) {
  let now = new Date();
  for (let t of tickets) {
    t.time = new Date(now);

    let duration = (t.durationS || t.duration * 60) * 1000;

    if (t.startedTime) {
      let started = new Date(t.startedTime);
      if (started.getTime() + duration < now.getTime()) {
        t.time = new Date(now);
      } else {
        let progress = now.getTime() - new Date(t.startedTime).getTime();
        t.time = new Date(now - progress);
        now.setTime(now.getTime() + duration);
      }
    } else {
      if (t.rdvTime != null && now < new Date(t.rdvTime)) {
        t.time = new Date(t.rdvTime);
        t.timeBefore = new Date(t.rdvTime) - now;
      } else {
        t.time = new Date(now);
      }
      now.setTime(t.time.getTime() + duration);
    }
  }
  return tickets;
}

export default ticketsWithTime;
