import React from "react";
import styled from "styled-components";

const InputWrapper = styled.div`
  width: 100%;
  flex: 6;

  label {
    position: relative;
    padding: 0;
    margin: 0;
    display: block;
    width: 100%;
    min-height: 45px;
    margin-top: 1rem;
  }

  .placeholder {
    position: absolute;
    display: block;
    top: 23px;
    z-index: 2;
    font-size: 16px;
    transition: all 200ms ease-in-out;
    width: 100%;
    cursor: text;
  }

  input {
    position: relative;
    width: 100%;
    top: 15px;
    z-index: 1;

    font-size: 16px;
    border: 0;
    border-bottom: 1px solid grey;
    transition: border-color 200ms ease-in-out;
    outline: none;
    padding: 0;
    margin: 0;
    height: 30px;

    .not-empty,
    :focus {
      border-bottom: 2px solid #049be5;
    }

    :focus ~ .placeholder,
    &.not-empty ~ .placeholder {
      top: 0;
      cursor: inherit;
      font-size: 14px;
      color: #049be5;
    }
  }
`;

const Input = ({ placeholder, empty, ...props }) => {
  return (
    <InputWrapper>
      <label>
        <input {...props} className={!empty ? "not-empty" : null} />
        <span className="placeholder">{placeholder}</span>
      </label>
    </InputWrapper>
  );
};

export default Input;
