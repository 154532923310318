import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Catalog from "./pages/Location";
import Ticket from "./pages/Ticket";
import Guidage from "./pages/Ticket/Guidage";
import "./App.css";
import DeviceLoginSafe from "./pages/Device/DeviceLoginSafe";
import DeviceLoginManual from "./pages/Device/DeviceLoginManual";
import HomePage from "./pages/Home";

const Device = React.lazy(() => import("./pages/Device"));
const Hub = React.lazy(() => import("./pages/Admin"));

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<HomePage />} />
        <Route
          path="/admin"
          exact
          element={
            <React.Suspense fallback={<></>}>
              <Hub />
            </React.Suspense>
          }
        />

        <Route path="/location/:slug/*" element={<Catalog />} />
        <Route path="/:slug/*" element={<Catalog />} />

        <Route path="/device/login-safe" element={<DeviceLoginSafe />} />
        <Route path="/device/login-manual" element={<DeviceLoginManual />} />

        <Route
          path="/device/*"
          element={
            <React.Suspense fallback={<></>}>
              <Device />
            </React.Suspense>
          }
        />

        <Route path="/t/:slug" element={<Ticket />}>
          <Route path="guidage" element={<Guidage />} />
        </Route>
        <Route path="/ticket-view/:slug/" element={<Ticket />}>
          <Route path="guidage" element={<Guidage />} />
        </Route>

        <Route
          path="*"
          element={
            <>
              <h1>404 not found</h1>
            </>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
