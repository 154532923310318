import styled from "styled-components";

export const Button = styled.button`
  &[diabled],
  :disabled {
    background: #3c3c3c;
  }
  background: #ffcc09;
  border-radius: 24px;

  font-weight: 900;
  font-size: 45px;
  line-height: 20px;
  text-align: center;

  color: #261d17;

  width: 80%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  outline: none;
`;

export const ButtonOutline = styled.button`
  border: 1px solid #c8c7cc;
  box-sizing: border-box;
  border-radius: 24px;

  font-weight: 900;
  font-size: 45px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
  background: none;
  width: 80%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;

  /* Ripple effect */
  background-position: center;
  transition: background 0.3s;

  :hover {
    background: rgba(255, 255, 255, 0.3)
      radial-gradient(circle, transparent 1%, rgba(255, 255, 255, 0.3) 1%)
      center/15000%;
  }
  :active {
    background-color: rgba(255, 255, 255, 0.3);
    background-size: 100%;
    transition: background 0s;
  }
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;

export default Button;
