import styled from "styled-components";
import useNow from "../../../../hooks/useNow";
import AvatarDefault from "../../../assets/barber.svg";

const CounterLayout = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-left: 21px;
  margin-right: 28px;
  align-items: center;
  z-index: 1;
`;
const Avatar = styled.img`
  max-width: 50px;
  border-radius: 50%;
`;
const ProgressBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  margin-left: 11px;
  color: white;
  background-color: rgba(35, 187, 172, 0.4);
  background: linear-gradient(
    90deg,
    rgba(35, 187, 172, 1) 0%,
    rgba(35, 187, 172, 1) ${(props) => props.progress},
    rgba(35, 187, 172, 0.4) ${(props) => props.progress},
    rgba(35, 187, 172, 0.4) 100%
  );
  height: 38px;
  width: 250px;
  border-radius: 8px;
  font-size: 17px;
  font-weight: 700;
`;

export const Counter = ({ ticketGoing, queueInfo }) => {
  const now = useNow();

  let { avatar } = queueInfo || "";
  let number = ticketGoing.number || ticketGoing.id;
  let startedTime = new Date(ticketGoing.startedTime);

  let progress = 0;
  if (startedTime) {
    const duration = ticketGoing.duration;
    const progressTime = (now.getTime() - startedTime.getTime()) / 1000;
    progress = Math.min((progressTime / duration) * 100, 100);
  }

  return (
    <CounterLayout>
      <Avatar src={avatar || AvatarDefault} />
      <ProgressBar progress={`${progress}%`}>
        <p>Ticket en cours</p>
        <p> n°{number} </p>
      </ProgressBar>
    </CounterLayout>
  );
};

export const CounterEmpty = ({ ticketGoing, queueInfo }) => {
  let { name, avatar } = queueInfo || "";
  return (
    <CounterLayout>
      <Avatar src={avatar || AvatarDefault} />
      <ProgressBar progress={0}>
        <p>{name}</p>
        <p> ... </p>
      </ProgressBar>
    </CounterLayout>
  );
};

export default Counter;
