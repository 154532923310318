import CardLight from "./CardLight";
import styled from "styled-components";

const CarouselLayout = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 15px;
  background-color: #f9f9f9;

  overflow-x: auto;
`;

const Carousel = ({ queueLines }) => {
  // classe les queues , en priorité ceux dispo , puis bientot dispo , puis indisponible
  queueLines.sort((a, b) => {
    if (a.status === "AVAILABLE" && b.status === "AVAILABLE") {
      if (a.tickets.length < b.tickets.length) {
        return -1;
      } else {
        return 1;
      }
    } else if (a.status === "AVAILABLE" && b.status !== "AVAILABLE") {
      return -1;
    } else {
      return 1;
    }
  });
  return (
    <CarouselLayout>
      {queueLines.map((q) => {
        return <CardLight key={q.id} queue={q} />;
      })}
    </CarouselLayout>
  );
};

export default Carousel;
