import styled from "styled-components";

const MapLayout = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 170px;
  justify-content: center;
  align-items: center;
  .black-filter {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 172px;
    background-color: black;
    opacity: 0.51;
  }
`;
const MapHeader = ({ location }) => {
  const safelocation = location.address.split(" ").join("+");
  const geoSearch = `${safelocation}+${location.zipCode}+${location.city}`;

  return (
    <MapLayout>
      <div className="black-filter"></div>
      <iframe
        title="map"
        frameBorder="0"
        style={{
          zIndex: 2,
          position: "absolute",
          top: "-100%",
          left: "-100%",
          width: "300%",
          height: "300%",
        }}
        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAl5e4vNS6wtPNbe9jseT19PNjfGTZfndk&q=${geoSearch}`}
        allowFullScreen
      ></iframe>
    </MapLayout>
  );
};

export default MapHeader;
