import React from "react";
import styled from "styled-components";

export const Input = styled.input`
  width: 100%;

  position: relative;
  width: 100%;
  top: 15px;
  z-index: 1;
  background: none;

  font-style: normal;
  font-weight: bold;
  font-size: 47px;
  line-height: 55px;
  display: flex;
  align-items: center;

  color: #ffcc09;

  border: 0;
  border-bottom: 1px solid grey;
  transition: border-color 200ms ease-in-out;
  outline: none;
  padding: 0;
  margin: 0;
  min-height: 30px;

  margin-left: 1rem;

  :focus,
  :valid {
    border-bottom: 2px solid grey;
  }
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
`;

export const SelectLayout = styled.label`
  select {
    background: none;

    font-style: normal;
    font-weight: bold;
    font-size: 47px;
    line-height: 55px;
    display: flex;
    align-items: center;

    color: #ffcc09;

    border: 0;

    transition: border-color 200ms ease-in-out;
    outline: none;
    padding: 0;
    margin: 0;
    min-height: 30px;
    appearance: none;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-bottom: 2px solid white;
  position: relative;
  top: 15px;

  :after {
    content: "";
    margin-left: -17px; /* remove the damn :after space */
    pointer-events: none;
    display: block;
    position: relative;
    top: 30%;
    width: 20px;
    height: 20px;
    margin-right: 1rem;
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    transform: rotate(45deg);
  }
`;

export const Select = ({ children, ...props }) => {
  return (
    <SelectLayout htmlFor="select">
      <select id="select" {...props}>
        {children}
      </select>
    </SelectLayout>
  );
};

export default Input;
