import styled from "styled-components";

const Button = styled.button`
  background: #ffcc09;
  border: none;
  border-radius: 24px;
  width: 90%;
  padding: 12px;
  margin: auto;
  max-width: 300px;

  font-style: normal;
  font-weight: 900;
  font-size: 17px;
  line-height: 20px;
  /* identical to box height, or 118% */

  text-align: center;

  color: #261d17;
  margin-left: auto;
  margin-right: auto;
`;

export const ButtonOutline = styled.button`
  background: #ffcc09;
  border: none;
  border-radius: 24px;
  width: 90%;
  padding: 12px;
  margin: auto;
  max-width: 300px;

  font-style: normal;
  font-weight: 900;
  font-size: 17px;
  line-height: 20px;
  /* identical to box height, or 118% */

  text-align: center;

  color: #261d17;
  background: #ffffff;
  color: #049be5;
`;

export const ButtonOutlineYellow = styled.button`
  background: #ffcc09;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border: none;
  border-radius: 24px;
  width: 90%;
  padding: 12px;
  margin: auto;
  max-width: 300px;

  font-style: normal;
  font-weight: 900;
  font-size: 17px;
  line-height: 20px;
  /* identical to box height, or 118% */

  text-align: center;

  color: ${(props) => (props.disabled ? "gray" : "#261d17")};
  background: ${(props) => (props.disabled ? "lightgray" : "#ffcc09")};
`;

export default Button;
