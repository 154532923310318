import styled from "styled-components";

const Avatar = styled.img`
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
`;
const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 136px;
  min-height: 91px;
  overflow-x: hidden;

  .name {
    font-size: 18px;
    font-weight: 700;
    color: #69696b;
  }
  .time {
    font-size: 16px;
    font-weight: 500;
    color: ${(props) =>
      props.available ? (props.active ? "#23BBAC" : "#049BE5") : "#979797"};
  }

  ${Avatar} {
    filter: ${(props) => (!props.available ? "grayscale(100%)" : null)};
  }
`;
const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
`;
const Dot = styled.div`
  min-height: 12px;
  min-width: 12px;
  border-radius: 50%;
  position: relative;
  left: 10px;
  align-self: flex-end;
  background-color: ${(props) =>
    props.available ? (props.active ? "#23BBAC" : "#049BE5") : "#979797"};
`;

const CardLight = ({ queue }) => {
  const available = queue.status === "AVAILABLE";
  const active = available && queue.tickets.length === 0;
  const queueTime = queue.time || new Date();
  let timeString = `${queueTime
    .getHours()
    .toString()
    .padStart(2, "0")}h${queueTime.getMinutes().toString().padStart(2, "0")}`;
  return (
    <Layout active={active} available={available}>
      <Container>
        <Avatar
          src={queue.avatar}
          alt={queue.name}
          width="50px"
          height="50px"
        />
        <Dot active={active} available={available} />
      </Container>
      <span className="name">{queue.name}</span>
      <span className="time">
        {available
          ? active
            ? "disponible"
            : `dispo à ${timeString}`
          : "indisponible"}
      </span>
    </Layout>
  );
};

export default CardLight;
